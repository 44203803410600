





























import { DetectionMarker } from '../Marker'
import { LFeatureGroup } from 'vue2-leaflet'
import { mapState } from 'vuex'
import DetectionPolyline from "@/components/Map/Detection/DetectionPolyline.vue";
import DetectionPopup from "@/components/Map/Detection/DetectionPopup.vue";
import {ESimulationType} from "@/store/modules/simulation";

const props = {
  visible: {
    type: Boolean,
    default: false
  },
  detection: {
    type: Object,
    default: () => ({
      key: null,
      length: 0,
      points: []
    })
  }
}

export default {
  name: 'SimulationDetectionLayer',
  props,
  data: () => ({
    tail: [],
    direction: 0
  }),
  components: {
    DetectionMarker,
    DetectionPolyline,
    DetectionPopup,
    LFeatureGroup
  },
  methods: {
    onSelect(key): void {
      this.$emit('selectDetection', key)
    }
  },
  computed: {
    ...mapState('sensors', ['activeKey']),
    icon(): number {
      switch(this.detection.type) {
        case ESimulationType.DRONE:
          return 13
        case ESimulationType.NOISE:
          return 11
        case ESimulationType.FIXED_EMITTER:
          return 12
        default:
          return 13
      }
    },
    selected(): boolean {
      return this.detection.drone_id === this.activeKey
    },
    liveTail(): Object[] {
      const { id, latitude, longitude } = this.detection
      return [
        {
          id,
          p: [latitude, longitude],
          l: 1
        },
        ...this.tail
      ]
    }
  },
  watch: {
    detection(v): void {
      if (!v) return

      this.tail.unshift({
        id: v.id,
        p: [v.latitude, v.longitude],
        l: 0
      })
    }
  }
}
