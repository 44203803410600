var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('l-feature-group',[_c('detection-marker',_vm._b({attrs:{"selected":_vm.selected},on:{"selectDetection":_vm.onSelect}},'detection-marker',Object.assign({}, _vm.detection,
      {activeKey: this.activeKey,
      level: _vm.icon}),false),[_c('detection-popup',_vm._b({attrs:{"slot":"popup","id":"detection-popup-2"},slot:"popup"},'detection-popup',{
        latitude: _vm.detection.latitude,
        longitude: _vm.detection.longitude,
        altitude: _vm.detection.altitude,
        speed: _vm.detection.speed,
        distance: _vm.detection.distance,
        adjustSpeed: false,
        type: 'radar'
      },false))],1),(_vm.selected)?_c('detection-polyline',{attrs:{"tail":_vm.liveTail}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }